@import './pages/admin/settings/style.module.scss';
@import './pages/admin/integrations/style.module.scss';
@import './pages/admin/setup/store/style.module.scss';
@import './pages/website/ThemeDesign/style.module.scss';
@import './pages/website/SocialMedia/style.module.scss';
@import './pages/website/Gallery/style.module.scss';
@import './pages/website/Popup/style.module.scss';
@import './pages/admin/reservation/setup/style.module.scss';

.gmnoprint {
  display: none;
}

.bb-header-1 {
  display: flex;
  margin-bottom: 24px;
  width: 100%;
  border-bottom: 1px solid #d2ddec;
  div.col-sm-12.col-md-4,
  h2 {
    width: 100%;
  }
}

.site-appearance,
.reservation-page {
  position: relative;
  .mtop {
    margin-top: 24px !important;
  }
  .input-block {
    max-width: 500px;
  }
  .bd-highlight.color-input-field {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .ant-col.ant-form-item-label {
    text-align: left;
    width: 100% !important;
  }
  .input-preview-image {
    width: 100%;
    height: 140px;
    border: 1px solid #d2ddec;
    border-radius: 10px;
    margin-top: 6px;
    display: none;
    align-items: center;
    justify-content: center;
    background-size: cover !important;
    background-position: center !important;
  }
  .form-file-block {
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }
  .form-file {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
  }
  .form-file-button {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    padding: 0 12px;
    height: 100%;
    font-size: 14px;
    color: #8e8e8e;
  }

  .input-file-name {
    font-size: 0.9rem;
  }
  .form-input {
    width: 100%;
    margin-top: 6px;
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 0.75rem;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #12263f;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d2ddec;
    border-radius: 0.375rem;
    // font-family: CerebriSans;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .form-input-block {
    position: relative;
  }

  .input-title {
    position: relative;
    font-size: 1rem;
    font-weight: 600;
  }
  .input-desc {
    position: relative;
    color: #8e8e8e;
    font-size: 0.9375rem;
    margin-top: 2px;
  }
}

// .site-appearance {
// max-width: 800px;
// }

.auth-container {
  label {
    font-weight: 600;
    margin-bottom: 4px !important;
  }
}

.swal2-title {
  color: #262626 !important;
}
.swal2-styled.swal2-confirm {
  background-color: #262626 !important;
  color: white !important;
}

.swal2-styled.swal2-deny {
  background-color: #8e8e8e !important;
  color: white !important;
}

.noDataFound {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #262626;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 10px;
}

.website-manager {
  position: relative;
  max-width: 100%;
  .bd-highlight.color-input-field {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .ant-col.ant-form-item-label {
    text-align: left;
    width: 100% !important;
    font-weight: 600;
    margin-bottom: 20px !important;
  }
  .input-preview-image {
    width: 100%;
    height: 140px;
    border: 1px solid #d2ddec;
    border-radius: 10px;
    margin-top: 16px;
    // display: none;
    align-items: center;
    justify-content: center;
    background-size: cover !important;
    background-position: center !important;
  }
  .form-file-block {
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }
  .form-file {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
  }
  .form-file-button {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    padding: 0 12px;
    height: 100%;
    font-size: 14px;
    color: #8e8e8e;
  }

  .input-file-name {
    font-size: 0.9rem;
  }
  .form-input {
    width: 100%;
    margin-top: 6px;
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 0.75rem;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #12263f;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d2ddec;
    border-radius: 0.375rem;
    // font-family: CerebriSans;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .form-input-block {
    position: relative;
  }

  .input-title {
    position: relative;
    font-size: 1rem;
    font-weight: 600;
  }
  .input-desc {
    // position: relative;
    color: #8e8e8e;
    font-size: 0.9375rem;
    margin-top: 5px;
    position: absolute;
    top: 15px;
    margin-bottom: 10px;
  }
  h5 {
    font-size: 1.25rem;
  }
  h6 {
    font-size: 1.6rem;
  }
  .ml-125 {
    margin-left: 125px;
  }
}

.login-link {
  min-width: 100px;
  display: flex;
  text-decoration: underline;
  color: #007bff;
}

.reports {
  .section-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    .bg-drop {
      background: rgba(255, 255, 255, 0.7);
      z-index: 0;
      position: absolute;
      height: 100%;
      width: 100%;
    }
    .ant-spin {
      z-index: 10 !important;
      perspective: 1000px !important;
    }
  }
  .ant-table-row:last-child {
    .ant-table-cell {
      font-weight: 600;
    }
  }
}

.change-password {
  .kit__utils__heading {
    padding-bottom: 16px;
    border-bottom: 1px solid #d2ddec;
    h5 {
      font-weight: 600 !important;
      letter-spacing: -0.02em;
      font-size: 1.625rem;
    }
  }
  .pass-block {
    display: block !important;
    max-width: 400px !important;
  }
  .with-icon {
    position: relative;
    .password-show {
      position: absolute;
      top: 7px;
      right: 1px;
      background: #f0f5f8;
      height: calc(100% - 8px);
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 1;
      perspective: 1000px;
      border-radius: 0 0.375rem 0.375rem 0;
    }
  }
}

.online-order-setup,
.reservation-page {
  .mh-t {
    min-height: 120px;
  }
  .pos-r {
    position: relative;
    .overlay-block {
      position: absolute;
      width: calc(100% - 314px);
      height: 100%;
      display: flex;
      left: 314px;
      align-items: center;
      justify-content: center;
      z-index: 10;
      .overlay-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 0.95;
      }
      .overlay-content {
        z-index: 11;
        text-align: center;
        p {
          font-size: 24px;
          font-weight: 500;
        }
      }
    }
  }
  .form-footer {
    position: sticky !important;
    z-index: 11;
  }
}

@media only screen and (max-width: 500px) {
  .ant-table-content {
    overflow-x: auto;
  }
  .order-history-page {
    .text-right {
      margin-bottom: 20px;
    }
  }
  .future-order-page {
    .text-right {
      margin-bottom: 20px;
    }
  }

  .online-order-setup,
  .reservation-page {
    .row.pdrht {
      padding-right: 0 !important;
      .card .card-body {
        .d-flex.mb-2.header {
          position: relative;
          max-width: 100%;
        }
        .d-flex.bd-highlight.field {
          margin-left: 0%;
        }
        .ml-auto.bd-highlight.mini {
          max-width: 140px;
          width: 100%;
        }
      }
    }
  }

  .store-setup,
  .reservation-page {
    .card .card-body {
      flex-wrap: wrap;
      .mb-2.header {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  .row.wrapR {
    flex-wrap: wrap-reverse !important;
    .google-map {
      height: 700;
      margin-top: 24px;
    }
    .col-xs-12,
    .col-sm-12 {
      width: 100% !important;
      max-width: 100% !important;
      flex: 0 0 100%;
    }
  }
  .website-widget {
    .kit__utils__heading {
      flex-wrap: wrap;
      h5 {
        margin-bottom: 15px;
      }
      .wcode {
        margin-left: 0 !important;
      }
    }
    .widget-container {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .color-picker-input {
      .btn-block {
        padding: 0% !important;
        border-radius: 4px;
      }
    }
  }
  .integrations {
    .ant-row {
      flex-wrap: wrap !important;
      .ant-col-12 {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }
  .collapse-bar-block {
    height: 0 !important;
    max-height: 0 !important;
    overflow: hidden !important;
    padding: 0 !important;
  }
  .input-form-container.coupon {
    flex-wrap: wrap;
    overflow-y: auto !important;
    .mb-2.header {
      max-width: 100%;
      margin-right: 0;
    }
    .coupon-form-block {
      margin-bottom: 180px !important;
    }
    .form-footer {
      position: fixed;
      padding-top: 16px !important;
      padding-bottom: 15px !important;
      .ant-form-item {
        margin-bottom: 0 !important;
      }
    }
  }

  .input-form-container.employee {
    flex-wrap: wrap;
    overflow-y: auto !important;
    .mb-2.header {
      max-width: 100%;
      margin-right: 0;
    }
  }
  .menu-manager-page {
    .card {
      border: 0 !important;
      box-shadow: none !important;
      .card-body {
        padding: 0 !important;
        button {
          margin-right: 0 !important;
        }
        .ant-tabs-mobile {
          margin-top: 40px;
          .ant-btn.ant-btn-secondary.ant-btn-lg {
            float: left !important;
          }
          input.ant-input {
            margin-top: 16px;
            margin-left: auto !important;
          }
          .ant-collapse-header {
            .row {
              .col {
                flex-grow: unset;
                width: 100%;
                flex-basis: unset;
                text-align: left !important;
              }
              .col-2 {
                flex: 0 0 100%;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .input-form-container {
    .card .card-body {
      flex-wrap: wrap;
      .row.d-header {
        max-width: 100% !important;
      }
    }
  }
}

.widget-container {
  max-width: 850px !important;
}

.bd-highlight.field.mttop {
  margin-top: 12px;
}
.card.show {
  display: block !important;
}

.red {
  background-color: #e80707;
}

.free-delivery-block {
  padding-bottom: 12px;
}

.select-btn.ant-btn.ant-btn-primary:focus {
  color: #262626 !important;
}

.galleryimageuploaditem .ant-form-item-control-input {
  bottom: 15px;
}
.promotionimageuploaditem .ant-form-item-control-input {
  bottom: 15px;
}

.themeDesign .form-footer {
  width: calc(100%) !important;
}

.themeDesign .col-9 {
  flex: 0 0 75% !important;
  max-width: 75% !important;
}

.social-media .col-9 {
  flex: 0 0 75% !important;
  max-width: 75% !important;
}

.gallery-section .col-12 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.popup-section .col-12 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.social-media .form-footer {
  width: calc(100%) !important;
}

.website-widget .col-9 {
  flex: 0 0 75% !important;
  max-width: 75% !important;
}

@media (max-width: 480px) {
  .themeDesign .col-9 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .social-media .col-9 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .gallery-section .col-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .popup-section .col-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .website-widget .col-9 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .widget-container .form-footer {
    width: calc(100% - 0px);
  }

  .gallery-section .d-flex {
    display: flex !important;
    padding-top: 25px !important;
  }

  .gallery-section .d-flex .style_floatingCreateButton__3Z9d0 {
    position: absolute !important;
    right: 30px !important;
    top: 40px !important;
  }

  .gallery-section .d-flex .style_floatingCreateButton_upload__2omVu {
    position: absolute !important;
    right: 195px !important;
    top: 40px !important;
  }

  .gallery-section {
    position: relative !important;
    width: 100% !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .themeDesign .preview-button {
    display: flex !important;
    padding-top: 0 !important;
  }

  .themeDesign .preview-button .style_floatingCreateButton__3Z9d0 {
    position: absolute !important;
    right: 30px !important;
    top: 9px !important;
  }

  .social-media .preview-button {
    display: flex !important;
    padding-top: 0 !important;
  }

  .social-media .preview-button .style_floatingCreateButton__3Z9d0 {
    position: absolute !important;
    right: 30px !important;
    top: 9px !important;
  }

  .popup-section .d-flex {
    display: flex !important;
    padding-top: 25px !important;
  }

  .popup-section .d-flex .style_floatingCreateButton__3Z9d0 {
    position: absolute !important;
    right: 30px !important;
    top: 40px !important;
  }

  .popup-section .d-flex .style_floatingCreateButton_upload__2omVu {
    position: absolute !important;
    right: 195px !important;
    top: 40px !important;
  }
  .popup-section {
    position: relative !important;
    width: 100% !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .page-form_divider__2kbcc {
    border-top: 1px dashed #d2ddec !important;
    margin: 0 0 !important;
  }
}

.removal-modal {
  .ant-modal-body {
    padding: 32px 32px 24px !important;
  }
  .font-size-16 {
    display: flex !important;
    overflow: hidden;
    color: #222 !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 1.4 !important;
    padding-right: 24px;
    .anticon-exclamation-circle {
      margin-top: 3px;
    }
  }
  .text-right {
    margin-top: 24px !important;
    .ant-btn {
      height: 32px !important;
    }
    .ant-btn-primary {
      margin-left: 12px !important;
    }
  }
}

.tax-search {
  .ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
    border-radius: 8px !important;
  }
  .ant-input-group-addon {
    display: none !important;
  }
}

.available-hours {
  .ant-radio-wrapper {
    display: block !important;
  }
}

.import-modal {
  width: 740px !important;
  .ant-modal-body {
    padding: 12px 24px !important;
  }
  .modal-header-sub {
    margin-top: 6px;
    font-size: 14px !important;
    color: #8e8e8e !important;
    line-height: 16px;
    font-weight: normal !important;
  }
  h4 {
    margin: 16px 0;
    font-size: 18px !important;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      .warning.text-icon {
        color: #f86d64;
      }
      .text-icon {
        color: #262626;
        font-size: 16px;
      }
      .text-head {
        width: 200px;
        display: inline-block;
        padding-left: 8px;
      }
    }
  }
  .ant-table-cell {
    padding: 6px 12px !important;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 4px !important;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 4px !important;
  }
  .btn-block {
    margin: 16px 0;
  }
}

.fade-in-image {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn s;
}
@keyframes fadeIn {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.sec-divider {
  border-bottom: 1px dashed #e4e9f0;
  width: 100%;
  margin-bottom: 12px;
}

.confirmation-modal {
  .ant-modal-footer {
    border-top: 0 !important;
    .ant-btn-primary {
      height: 32px !important;
      padding: 0 20px !important;
    }
  }
}

.website-manager .themeDesign .bd-highlight.color-input-field {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.swiper-slide.swiper-slide-active .box {
  z-index: 999999;
  perspective: 1000px;
}

// .billing-block .plan-detail-list .box{
//   .select-btn.disabled{
//     cursor: pointer !important;
//     opacity: 1 !important;
//     pointer-events: inherit !important;
//   }
// }

.reservation-floor-plan-page {
  .ant-tabs-nav {
    width: calc(100% - 235px) !important;
  }
  .act_btns {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 130px);
    flex-wrap: wrap;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid #d2ddec !important;
  }
  .ant-tabs-tab {
    padding: 16px 0 !important;
    .r-tab-header {
      display: flex;
      align-items: center;
      .pane-title {
        padding-right: 12px;
        outline: none !important;
        border: 0 !important;
        .pane-editor {
          background-color: rgb(228, 225, 225);
          border-radius: 10px;
          position: relative;
        }
        input {
          width: auto;
          border: 0;
          padding: 4px 12px;
          background: transparent;
        }
        .anticon.anticon-check {
          position: absolute;
          margin-right: 0 !important;
          right: 5px;
          display: flex;
          align-items: center;
          top: 0;
          height: 100%;
          border-left: 1px solid #ddc4c4;
          padding: 0 4px 0 8px;
        }
      }
      .pane-edit {
        display: none;
      }
      .pane-delete {
        display: none;
      }
    }
  }
  .ant-tabs-tab-active {
    .r-tab-header {
      .pane-edit,
      .pane-delete {
        display: inline;
        margin-top: 2px;
      }
    }
  }
  .mobile-heading {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .floor-plan-container {
    display: flex;
    height: calc(100vh - 130px);
    .reservation-floor-plan-sidebar {
      width: 400px;
      min-width: 400px;
      background: white;
      background-color: white;
      height: 100%;
      border-right: 1px solid #d2ddec;
    }
    .reservation-floor-plan-canvas {
      height: calc(100% - 40px);
      width: 100%;
      .floor-top-header {
        border-bottom: 1px solid #d2ddec;
        margin-bottom: 24px;
        height: 50px;
        display: flex;
        align-items: center;
      }
      .r-content {
        display: flex;
        width: 100%;
        margin-bottom: 12px;
        padding: 0 0 12px 0;
        border-bottom: 1px solid #d2ddec;
        .r-header.ts {
          min-width: 110px;
        }
        .r-header {
          // min-width: 140px;
          margin-right: 32px;
          font-size: 16px;
          font-weight: 500;
          display: flex;
          align-items: center;
        }
        .r-shapes {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          .ts.r-shape.r-shape-square {
            min-width: 40px;
            max-width: 40px;
          }
          .ts.r-shape.r-shape-circle {
            min-width: 42px;
            max-width: 42px;
          }
          .r-shape {
            position: relative;
            cursor: move;
            width: 100%;
            margin-right: 28px;
            background: white;
          }
          .r-shape-square {
            // border: 3px solid #d2ddec;
            border: 3px solid #000;
            width: 40px;
            height: 40px;
            border-radius: 4px;
          }
          .r-shape-circle {
            border: 3px solid #333;
            width: 42px;
            height: 42px;
            border-radius: 50%;
          }
          .r-shap-custom {
            width: 40px;
            img {
              width: 100%;
            }
          }
          .r-shape-line {
            width: 40px;
            height: 40px;
            border-left: 4px solid #333;
          }
        }
        .act-btn-list {
          display: flex;
          margin-top: -60px;
          height: 44px;
          border-bottom: 1px solid #d2ddec;
          .ant-btn {
            margin-left: 6px !important;
          }
          .save_btn {
            background: #262626 !important;
            color: #fff;
          }
        }
      }
      .floor-content {
        height: calc(100% - 30px);
        width: calc(100% - 0px);
        margin-left: 0;
        background: #fafafa;
        background-size: 10px 10px;
        background-image: linear-gradient(to right, rgb(231, 229, 229) 1px, transparent 1px),
          linear-gradient(to bottom, rgb(231, 229, 229) 1px, transparent 1px);
      }
    }
  }
}

.floor-plan-table {
  .ant-drawer-mask {
    background-color: rgba(0, 0, 0, 0.25) !important;
  }
  .ant-drawer-content {
    background-color: transparent !important;
  }
  .ant-drawer-body {
    background-color: rgba(255, 255, 255, 0.88) !important;
  }
  .reservation-floor-plan-form {
    .form-item {
      margin-bottom: 18px;
      .flabel {
        margin-bottom: 4px;
        font-weight: 500;
      }
      .ant-input {
        padding: 6.5px 11px;
        font-size: 16px;
        border: 1px solid #e4e9f0;
        border-radius: 8px;
      }
    }
    .form-item.checkbox {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-bottom: 12px;
      .flabel {
        font-weight: normal;
        margin-left: 6px;
      }
      .ant-checkbox-inner {
        border: 1px solid #262626 !important;
      }
    }
    .form-item.rangepicker {
      margin-bottom: 24px;
      margin-top: 24px;
      .ant-slider-rail {
        background: #8e8e8e;
      }
      .range-label {
        display: flex;
        margin-bottom: 14px;
        span {
          width: 100%;
          display: inline-block;
        }
        span:last-child {
          text-align: right;
        }
      }
    }
    .form-item.shape {
      margin-top: 24px;
      margin-bottom: 24px;
      .r-shapes {
        margin-top: 8px;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        .r-shape {
          width: 100%;
          margin-right: 28px;
          cursor: pointer;
          transition: 0.3s ease-in-out;
        }
        .r-shape-square {
          width: 40px;
          height: 40px;
          border-radius: 2px;
          position: relative;
          border: 2px solid #d2ddec;
        }
        .r-shape-square::after {
          content: '';
          position: absolute;
          width: calc(100% - 18px);
          height: calc(100% - 18px);
          top: 9px;
          left: 9px;
          border: 2px solid #d2ddec;
          border-radius: 1px;
        }
        .r-shape-circle {
          border: 2px solid #d2ddec;
          width: 42px;
          height: 42px;
          border-radius: 4px;
          position: relative;
        }
        .r-shape-circle::after {
          content: '';
          position: absolute;
          width: calc(100% - 16px);
          height: calc(100% - 16px);
          top: 8px;
          left: 8px;
          border: 2px solid #d2ddec;
          border-radius: 50%;
        }
        .r-shap-custom {
          width: 40px;
          img {
            width: 100%;
          }
        }
        .r-shape.selected {
          background: rgba(38, 38, 38, 1);
          border-radius: 3px;
          pointer-events: none;
          border-color: unset !important;
        }
        .r-shape.r-shap-custom.selected {
          background: rgba(38, 38, 38, 0.21) !important;
          padding: 8px;
        }
      }
    }
    .form-item-btn {
      border-top: 1px solid #d2ddec;
      .ant-btn {
        margin-top: 12px;
        background: #262626;
        color: #fff;
      }
    }
  }
}

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  display: none !important;
}

.alert-message-bar {
  padding-bottom: 10px;
  width: auto;
  margin: 0 auto;
}