.integrations {
  .partners-devices-sec {
    margin-top: 80px;
    .partners-devices-titles {
      margin-bottom: 40px;
      .sec-title {
        font-weight: 600 !important;
        // font-family: MCerebriSans !important;
        // letter-spacing: -0.02em;
        font-size: 1.85rem;
        color: #12263f;
      }
      .sec-desc {
        margin-top: 8px;
        font-size: 1.1rem;
      }
    }
    .device-card {
      box-shadow: 0 4px 10px 0 rgba(20, 19, 34, 0.01), 0 0 10px 0 rgba(20, 19, 34, 0.005);
      background-color: #fff !important;
      background-clip: border-box;
      border: 1px solid #edf2f9;
      border-radius: 0.5rem;
      cursor: default;
      .card-heading {
        font-size: 20px !important;
      }
      .soon-tag {
        position: absolute;
        right: 32px;
        top: 24px;
        color: #3b88c3;
      }
      .ant-skeleton-content {
        .c-title {
          margin-top: 0 !important;
        }
      }
      .ant-list-item-meta-content {
        .card-desc {
          text-align: left;
          font-size: 1.05rem !important;
          a {
            margin-left: 2px;
            span {
              padding-left: 4px;
            }
          }
        }
      }
    }
    .manage-btn {
      border-radius: 5px !important;
      font-size: 12px !important;
    }
  }
}

.deliverect {
  .input-block {
    max-width: 500px;
  }
  .bd-highlight.color-input-field {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .input-title {
    position: relative;
    font-size: 1rem;
    font-weight: 600;
  }
  .input-desc {
    position: relative;
    color: #8e8e8e;
    font-size: 0.9375rem;
    margin-top: 2px;
  }
}

.radioStyle {
  height: 30px;
  line-height: 30px;
}
