.online-order-setup {
  max-width: 1250px;
  .pdrht {
    padding-right: 180px !important;
  }
  .dashborder-binary {
    border-bottom: 1px dashed #d2ddec !important;
    margin-bottom: 24px;
  }
  .card.line-inputs .card-body {
    .ant-form-item-control-input-content {
      margin-right: 6px;
      .ant-select {
        .ant-select-selector {
          height: 35px;
        }
      }
    }
    .ant-input-group-wrapper {
      .ant-input-group {
        .ant-input {
          height: 35px !important;
        }
      }
    }
  }
  .row .col-lg-12 .card.site-appearance {
    border-bottom: 0 !important;
  }
  .row .col-lg-12 .card.line-inputs.bt1 {
    border-top: 1px dashed #d2ddec !important;
  }
  .row .col-lg-12 .card {
    border: 0px !important;
    border-color: unset !important;
    box-shadow: none !important;
    border-bottom: 1px dashed #d2ddec !important;
    .card-body {
      padding-left: 0 !important;
      padding-right: 0 !important;
      .ml-auto.bd-highlight.mini {
        max-width: 200px;
      }
      .d-flex.bd-highlight.field.input-container {
        display: block !important;
        .no-input-block {
          .ant-form-item {
            margin-bottom: 0 !important;
          }
          label {
            color: rgb(18, 38, 63) !important;
            font-size: 1rem !important;
            font-weight: 600;
            font-size: 15px !important;
            height: auto !important;
          }
        }
        .input-block .ant-form-item-label {
          width: 100%;
          text-align: left;
          margin-top: -15px;
          label {
            color: rgb(18, 38, 63) !important;
            font-size: 1rem !important;
            font-weight: 600;
            font-size: 15px !important;
            height: auto !important;
          }
        }
      }
    }
    .d-flex.mb-2.header {
      position: absolute;
      left: 0;
      width: 100%;
      max-width: 300px;
    }
    .d-flex.bd-highlight.field {
      margin-left: 314px;
    }
  }
  .dashborder {
    margin-bottom: 10px;
    border-bottom: 1px dashed #d2ddec !important;
  }
  .row .col-lg-12 .card.payment-opts {
    .bd-highlight {
      width: -webkit-fill-available;
    }
    .ant-form-item-control-input-content {
      margin-right: 24px;
      .ant-checkbox-group label.ant-checkbox-wrapper-checked {
        background-color: #fafafa;
      }
      .ant-checkbox-group label {
        min-height: 70px;
        max-width: 225px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: #fff;
        border-radius: 6px;
        border: 1px solid #e4e9f0;
        flex-wrap: wrap;
        padding: 12px 10px;
        box-shadow: 0 4px 10px 0 rgba(20, 19, 34, 0.03), 0 0 10px 0 rgba(20, 19, 34, 0.02);
        span.ant-checkbox {
          width: auto;
        }
        .ant-checkbox + span {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
  .row .col-lg-12 .card.bb0 {
    border-bottom: 0 !important;
  }
  .mtop {
    padding-bottom: 12px;
  }
  .mini-select {
    .ant-select-selector {
      height: 30px !important;
      .ant-select-selection-item {
        line-height: 30px !important;
        font-size: 15px !important;
      }
      .ant-select-selection-search {
        input {
          height: 29px !important;
        }
      }
    }
  }
  .ptop {
    padding-top: 18px !important;
  }
}
