.website-manager {
  position: relative;
  .websitemanagerMaxWidth .row {
    max-width: 500px;
  }
  .card {
    border: 0 !important;
    border-color: unset !important;
    box-shadow: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    .card-body {
      padding-left: 0 !important;
      padding-right: 0 !important;
      display: flex;
      .mb-4.header,
      .mb-2.header {
        max-width: 300px;
        width: 100%;
      }
      .ant-form-item-label {
        label {
          color: rgb(18, 38, 63) !important;
          font-size: 1rem !important;
          font-weight: 600;
          // font-size: 15px !important;
          height: auto !important;
        }
      }
    }
  }
  .card.dashborder {
    border-bottom: 1px dashed #d2ddec !important;
  }
  .form-footer {
    box-shadow: 0 -6px 5px -4px rgba(0, 0, 0, 0.1);
    left: 18px;
    position: fixed;
    bottom: 0 !important;
    padding: 24px 15px;
    border-top: 1px dashed #d2ddec;
    z-index: auto;
    background-color: #f9fbfd;
    transition: box-shadow 0.2s linear;
    width: calc(100% - 48px);
    text-align: center;
    .center-align {
      text-align: center !important;
    }
  }
}
