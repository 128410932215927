.getting-started {
  .kit__utils__heading {
    padding-bottom: 16px;
    border-bottom: 1px solid #d2ddec;
    width: 100%;
    h5 {
      width: 100%;
      font-weight: bold !important;
      // font-family: MCerebriSans;
      letter-spacing: -0.02em;
      font-size: 1.625rem;
    }
  }
  .onboarding {
    .onboarding-header::before,
    .onboarding-header::after {
      content: " ";
      display: block;
      border-bottom: 3px solid #d2ddec;
    }
    .onboarding-header {
      text-align: left;
      margin-top: 3.5rem;
      margin-bottom: 0.75rem;
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      grid-template-rows: 1.4rem 0;
      grid-gap: 1rem;
      span {
        // font-family: MCerebriSans;
        font-weight: bold !important;
        letter-spacing: -0.02em;
        font-size: 1.7rem;
        color: #12263f;
      }
    }
    p.header-desc {
      margin-top: 12px;
      text-align: center;
      font-size: 1.15rem !important;
      width: 100%;
      margin-bottom: 2.25rem !important;
    }
    .onboarding.card {
      border: 1px solid #d2ddec;
      margin-bottom: 1.5rem;
      box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: border-box;
      border: 1px solid #d2ddec !important;
      border-radius: 0.5rem;
      .justify-content-between {
        justify-content: space-between !important;
      }
      .d-flex {
        display: flex !important;
      }
      .onboarding-required-steps-list {
        display: grid;
        grid-template-columns: minmax(36%, 15rem) auto;
        border-bottom-left-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;
        .onboarding-required-steps-list-menu {
          .list-group {
            display: flex;
            flex-direction: column;
            padding-left: 0;
            margin-bottom: 0;
            height: 100%;
            margin-top: -1px;
            .list-group-item:first-child {
              border-top-left-radius: 0.375rem;
            }
            .list-group-item:last-child {
              border-bottom-left-radius: 0.375rem;
            }
            .list-group-item.active {
              z-index: 2;
              background-color: #f0f5f8 !important;
              color: #262626 !important;
              border-left: 5px solid #262626;
              margin-top: 0;
            }
            .list-group-item.completed {
              pointer-events: none;
              span.d-flex {
                text-decoration: line-through;
              }
            }
            .list-group-item.disabled {
              opacity: 0.6;
            }
            .list-group-item {
              border-left: 5px solid #6e84a3;
              background-color: #ebeef2;
              color: #6e84a3;
              border-width: 1px 0 0 5px !important;
              border-radius: 0;
              transition: background-color linear 0.2s;
              background-color: #fff;
              border-color: #d2ddec;
              color: #262626;
              height: 5.55rem;
              padding-top: 2.15rem;
              padding-bottom: 2.15rem;
              cursor: pointer;
            }
          }
        }
        .onboarding-required-steps-list-panel {
          border-left: 1px solid #d2ddec;
          .h100 {
            height: 100%;
          }
          .card-body.h100.step {
            background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
            background-size: 20rem;
            background-position: right bottom -10rem;
            background-repeat: no-repeat;
            h2 {
              font-weight: bold !important;
              // font-family: MCerebriSans;
              letter-spacing: -0.02em;
              font-size: 1.35rem;
              margin-bottom: 12px;
            }
            .mb-5,
            .my-5 {
              margin-bottom: 2.25rem !important;
            }
            p {
              font-size: 1.1rem;
              color: #12263f;
            }
            .btn-primary {
              color: #fff !important;
              background-color: #262626 !important;
              border-color: #262626 !important;
              border: 1px solid transparent;
              padding: 0.5rem 0.75rem;
              font-size: 1.1rem;
              border-radius: 0.375rem;
              box-shadow: none !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 501px) {
  body
    .getting-started
    .onboarding
    .onboarding.card
    .onboarding-required-steps-list
    .onboarding-required-steps-list-menu
    .list-group
    .card-body.step {
    display: none !important;
  }
}

@media only screen and (min-width: 280px) and (max-width: 500px) {
  body {
    .getting-started .onboarding .onboarding-header::after,
    .getting-started .onboarding .onboarding-header::before {
      display: none;
    }
    .getting-started .onboarding {
      .onboarding-header {
        margin-top: 24px;
        display: block;
      }
      .onboarding.card {
        display: block;
        width: 100%;
        overflow: hidden;
        .onboarding-required-steps-list {
          display: block;
          .onboarding-required-steps-list-menu .list-group .list-group-item.completed .card-body.step.h100.show-cb {
            max-height: 0;
            opacity: 0;
            overflow: hidden;
          }
          .onboarding-required-steps-list-menu .list-group .list-group-item {
            flex-wrap: wrap;
            height: auto;
            .card-body.step.h100.show-cb {
              max-height: 1000px;
              opacity: 1;
              overflow: unset;
              width: 100%;
            }
            .card-body.step.h100 {
              max-height: 0;
              opacity: 0;
              padding: 0 !important;
              overflow: hidden;
              transition: 0.4s ease-in-out;
              .pad-5 {
                padding: 1.33rem 0rem;
                h2 {
                  font-size: 18px;
                }
              }
            }
          }
          .onboarding-required-steps-list-panel {
            display: none;
          }
        }
      }
    }
  }
}
