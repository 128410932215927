.dashboard-page {
  .success {
    color: #30c382 !important;
  }
  .failure {
    color: #e40;
  }
  .noDataFound {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #262626;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 10px;
  }
  .section-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    .bg-drop {
      background: rgba(255, 255, 255, 0.7);
      z-index: 0;
      position: absolute;
      height: 100%;
      width: 100%;
    }
    .ant-spin {
      z-index: 10 !important;
      perspective: 1000px !important;
    }
  }
}
