@import 'mixins.scss';

// dark theme
[data-vb-theme='dark'] {
  .container {
    background: transparent !important;
  }
}

.innerCard {
  text-align: center;
  width: 100%;
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  max-width: 430px;
  background: #fff;
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
}

.logo {
  max-width: 180px;
  width: 100%;
  margin-bottom: 10px;
}

.links {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    display: inline-block;
    margin: 0 5px;
  }
}

.dot {
  display: inline-block;
  margin: 0 3px;
  &::after {
    display: inline-block;
    content: '·';
    color: #8e8e8e;
  }
}

.bgImage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.formcon {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.registerAuthContainer {
  position: relative;
  max-width: 950px !important;
  // min-height: 100vh;
  margin: auto;
}

.registerAuthContainerBlock {
  max-width: 950px !important;
  position: initial;
  min-height: 100vh;
  z-index: 10;
}

.registerAuthContent {
  max-width: 950px !important;
  overflow: inherit;
  min-height: 100vh;
  padding: 0 2rem;
  padding-top: 1.2rem;
}

.registerAuthContainerImg {
  z-index: 1;
}
